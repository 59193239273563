import React, { FC, useState, useEffect, useCallback } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import Collapse from 'react-bootstrap/Collapse';

import IconCustom from 'gatsby-theme-dettol/src/components/common/IconCustom';
import UmbracoImage from 'gatsby-theme-dettol/src/components/common/Image/UmbracoImage';
import extractUrlFromMultiPicker from 'gatsby-theme-dettol/src/utils/urlMultiPicker';
import { isBrowser, prepareClassName } from 'gatsby-theme-dettol/src/utils/browser';
import {
  ICategoryWrapperProps,
  IItemColumnProps,
  PanelsBlockName,
  IHeaderNavDropdownMenuProps,
} from './models.d';

import './HeaderNavDropdownMenu.scss';

const defaultMenuWidth = 992;

const ProductCategoryWrapper: FC<ICategoryWrapperProps> = ({ name, children }) => {
  const [isDesktopMenu, setIsDesktopMenu] = useState(true);
  const [isToggle, setToggle] = useState(false);

  const menuTitleMobileClasses = classNames('dt-header__dropdown-menu-title', {
    'dt-header__dropdown-menu-title--active': isToggle,
  });

  useEffect(() => {
    if (isBrowser()) {
      const handleResize = () => {
        setIsDesktopMenu(window.innerWidth >= defaultMenuWidth);
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleToggle = () => {
    setToggle(!isToggle);
  };

  const dropdownContent =
    isDesktopMenu ? (
      <>
        <strong className="dt-header__dropdown-menu-title">{name}</strong>
        <div className="dt-header__dropdown-menu-items">{children}</div>
      </>
    ) : (
      <>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <strong className={menuTitleMobileClasses} onClick={handleToggle}>
          {name}
          <span className="dt-header__dropdown-menu-caret">
            <IconCustom icon="next_arrow" />
          </span>
        </strong>
        <Collapse in={isToggle}>
          <div className="dt-header__dropdown-menu-items">{children}</div>
        </Collapse>
      </>
    );

  return <div className="dt-header__dropdown-menu-col">{dropdownContent}</div>;
};

const ItemColumn: FC<IItemColumnProps> = ({ name, link, image }) => (
  <div className="dt-header__dropdown-menu-item">
    <Link className={`dt-header__dropdown-menu-item-holder ${prepareClassName(name)}`} to={link}>
      <UmbracoImage image={image} alt={name} />
      <span>{name}</span>
    </Link>
  </div>
);

const HeaderNavDropdownMenu: FC<IHeaderNavDropdownMenuProps> = ({ panels }) => {
  const sortWithNullImage = useCallback(
    (a, b) => !a.image - !b.image || a.id?.localeCompare(b.id),
    []
  );

  return (
    <div className="dt-header__dropdown-menu">
      <div className="dt-header__dropdown-menu-holder">
        <div className="dt-header__dropdown-menu-frame">
          <div className="dt-header__dropdown-menu-row" data-col={panels.length}>
            {panels.map((panel) => {
              switch (panel.structure) {
                case PanelsBlockName.SUB_CATEGORIES:
                  return (
                    <ProductCategoryWrapper
                      key={`${panel.structure}-${panel.properties.name}}`}
                      name={panel.properties.name}
                    >
                      {panel.properties.subCategories
                        ?.sort(sortWithNullImage)
                        .map((subCategory) => (
                          <ItemColumn
                            key={`category-wrapper-${panel.structure}-${subCategory.name}`}
                            name={subCategory.title || subCategory.name}
                            image={subCategory.image}
                            link={extractUrlFromMultiPicker(subCategory.link)}
                          />
                        ))}
                    </ProductCategoryWrapper>
                  );

                case PanelsBlockName.PRODUCTS:
                  return (
                    <ProductCategoryWrapper
                      key={`${panel.structure}-${panel.properties.name}}`}
                      name={panel.properties.name}
                    >
                      {panel.properties.products?.map(({ properties }) => (
                        <ItemColumn
                          key={`category-wrapper-${panel.structure}-${properties.name}`}
                          name={properties.name}
                          image={properties.image}
                          link={extractUrlFromMultiPicker(properties.link)}
                        />
                      ))}
                    </ProductCategoryWrapper>
                  );

                default:
                  return null;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderNavDropdownMenu;
